<template>
  <div>
    <div class="head">
      <img @click="goBack" src="./assets/images/返回.png" alt="" />
      <span>{{ title }}</span>
      <span></span>
    </div>
    <FarmerInfo v-if="title == '选择屋顶' || title == '现场踏勘'" />
    <!-- <router-view /> -->
    <router-view v-slot="{Component}">
      <keep-alive :include="includeList">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>

</template>
<script>
import FarmerInfo from './components/FarmerInfo'
import { useRouter, useRoute } from 'vue-router'
import { reactive, watch, toRefs, ref } from 'vue'

import { getBaseInfo } from "@/hooks/useQingtian"
import { getProjectBasicInfo } from "@/hooks/useJinlang"

export default {
  components: {
    FarmerInfo
  },
  setup() {
    // 晴天获取农户信息
    if (window.customer === 'qingtian') {
      getBaseInfo()
    } else { // 锦浪获取农户信息
      getProjectBasicInfo()
    }
    const includeList = ref(["FlatRoof", "SlopingRoof", "Innerbuilding", "Outerbuilding"]);
    const router = useRouter()
    const route = useRoute()
    const data = reactive({
      customer: window.customer,
      title: '选择屋顶'
    })

    const methodsMap = {
      goBack() {
        router.go(-1)
      },

    }
    watch(
      () => route.path,
      (n) => {
        if (n === '/home') {
          data.title = '选择屋顶'
        } else if (
          n === '/flatRoof' ||
          n === '/slopingRoof' ||
          n === '/innerbuilding' ||
          n === '/outerbuilding' ||
          n === '/compositeRoof'
        ) {
          data.title = '现场踏勘'
        } else if (
          n === '/flatRoofPreview' ||
          n === '/slopingRoofPreview' ||
          n === '/innerbuildingPreview' ||
          n === '/outerbuildingPreview'
        ) {
          data.title = '预览'
        } else if (n === '/drawing') {
          data.title = '排队出图'
        } else if (n === '/confirmLayout') {
          data.title = '确认排布图'
        }
      }
    )
    // const configWx = reactive({
    //   // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出
    //   // debug: true
    //   appId: 'wx20c4916d8dafed98', // 必填，公众号的唯一标识
    //   timestamp: "1621361063790", // 必填，生成签名的时间戳
    //   nonceStr: "sign_xxxxxxxxx", // 必填，生成签名的随机串
    //   signature: "sign", // 必填，签名
    //   jsApiList: ['wx-open-launch-weapp', 'updateAppMessageShareData'], // 必填，需要使用的JS接口列表
    //   openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
    // })
    // // 配置SDK
    // this.wx.config(configWx);
    return { route, ...toRefs(data), ...methodsMap, includeList }
  }
}
</script>
<style lang="scss">
body,
html {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  color: #2c3e50;
  background-color: #f3f3f4;
  padding-bottom: 15px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.head {
  width: 375px;
  height: 50px;
  line-height: 50px;
  background: #47c265;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    margin-left: 15px;
  }
  span {
    font-weight: 800;
    color: #ffffff;
    font-size: 20px;
    &:nth-last-child(1) {
      width: 18px;
      height: 18px;
      margin-left: 15px;
    }
  }
}
</style>
