import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
  path: '/',
  redirect: "/home",
},
{
  path: '/home',
  name: 'Home',
  component: () => import('@/views/Home.vue')
},
{
  path: '/flatRoof',
  name: 'FlatRoof',
  component: () => import('@/views/flatRoof/FlatRoof')
},
{
  path: '/flatRoofPreview',
  name: 'FlatRoofPreview',
  component: () => import('@/views/flatRoof/FlatRoofPreview')
},
{
  path: '/slopingRoof',
  name: 'SlopingRoof',
  component: () => import('@/views/slopingRoof/SlopingRoof')
},
{
  path: '/slopingRoofPreview',
  name: 'SlopingRoofPreview',
  component: () => import('@/views/slopingRoof/SlopingRoofPreview')
},
{
  path: '/innerbuilding',
  name: 'Innerbuilding',
  component: () => import('@/views/InnerbuildingRoof/Innerbuilding')
},
{
  path: '/innerbuildingPreview',
  name: 'InnerbuildingPreview',
  component: () => import('@/views/InnerbuildingRoof/InnerbuildingPreview')
},
{
  path: '/outerbuilding',
  name: 'Outerbuilding',
  component: () => import('@/views/outerbuildingPlatformRoof/Outerbuilding')
},
{
  path: '/outerbuildingPreview',
  name: 'OuterbuildingPreview',
  component: () => import('@/views/outerbuildingPlatformRoof/OuterbuildingPreview')
},
{
  path: '/compositeRoof',
  name: 'CompositeRoof',
  component: () => import('@/views/compositeRoof/CompositeRoof')
},
{
  path: '/drawing',
  name: 'Drawing',
  component: () => import('@/views/Drawing')
},
{
  path: '/confirmLayout',
  name: 'ConfirmLayout',
  component: () => import('@/views/ConfirmLayout.vue')
},
{
  path: '/previewPdf',
  name: 'PreviewPdf',
  component: () => import('@/views/PreviewPdf.vue')
}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.afterEach((to, from) => {
  if (from.path === '/flatRoof' && to.path === '/home') {
    sessionStorage.clear()
    location.reload()
  }
  if (from.path === '/slopingRoof' && to.path === '/home') {
    sessionStorage.clear()
    location.reload()
  }
  if (from.path === '/slopingRoofPreview' && to.path === '/slopingRoof') {
    location.reload()
  }
  if (from.path === '/slopingRoofPreview' && to.path === '/home') {
    sessionStorage.clear()
    location.reload()
  }

  if (to.path === '/drawing' && from.path === '/confirmLayout') {
    sessionStorage.removeItem('getListImg')
  }
  if (from.path === '/flatRoofPreview' && to.path === '/flatRoof') {
    // 刷新页面方法
    location.reload()
  }
  if (from.path === '/flatRoofPreview' && to.path === '/home') {
    sessionStorage.clear()
    location.reload()
  }
  // 刷新页面方法
  // location.reload()
  // }
  if (from.path === '/innerbuildingPreview' && to.path === '/innerbuilding') {
    // sessionStorage.clear()
    location.reload()
  }
  if (from.path === '/innerbuildingPreview' && to.path === '/home') {
    sessionStorage.clear()
    location.reload()
  }

  if (from.path === '/innerbuilding' && to.path === '/home') {
    sessionStorage.clear()
    location.reload()
  }
})

export default router