import axios from 'axios';
// 内部接口文件

// 创建axios实例
const instance = axios.create({
  baseURL: window.api.apiURL,
  timeout: 5000
});
// 请求拦截器
instance.interceptors.request.use(function (config) {
  if (config.method.toLowerCase() === 'get') {
    config.params = config.data
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
// 响应拦截器
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});
export default instance;
