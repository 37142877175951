// 引入axios实例
import request from "./axios";
// 获取组件列表(晴天)
function GetComponentDict(data) {
  return request({
    method: "POST",
    url: "Dict/GetComponentDict",
    data
  });
}
// 获取农户信息(锦浪)
function ProjectBasicInfo(data) {
  return request({
    method: "GET",
    url: "ProjectFlatRoof/ProjectBasicInfo?projectId=" + data,
    data
  });
}
// 添加平屋顶数据
function AddFlatRoof(data) {
  return request({
    method: "POST",
    url: "ProjectFlatRoof/Add",
    data
  });
}
// 添加其他屋顶数据
function AddObliqueRoof(data) {
  return request({
    method: "POST",
    url: "ObliqueRoof/Add",
    data
  });
}
// 添加任务
function AddTask(data) {
  return request({
    method: "POST",
    url: "TaskDrawing/Add?ProjectID=" + JSON.parse(sessionStorage.getItem('projectAddRequest')).ProjectID + ',' + data,
  });
}
// 获取发起任务的状态
function GetTaskState(data) {
  return request({
    method: "POST",
    url: "TaskDrawing/GetTaskState?ProjectID=" + JSON.parse(sessionStorage.getItem('projectAddRequest')).ProjectID + ',' + data,
    data
  });
}
// 获取图纸列表
function GetList(data) {
  return request({
    method: "POST",
    url: "ProjectRelation/GetList?ProjectID=" + JSON.parse(sessionStorage.getItem('projectAddRequest')).ProjectID + "&t=" + new Date().getTime(),
    data
  });
}
// 更新任务
function UpdataTask(data) {
  return request({
    method: "POST",
    url: "TaskDrawing/Add?ProjectID=" + JSON.parse(sessionStorage.getItem('projectAddRequest')).ProjectID + ',' + data,
  });
}
// 更新排布图
function UpdateJson(data) {
  return request({
    method: "POST",
    url: "Project/UpdateJsonFromVUE",
    data
  });
}
// 未生成图纸时错误信息查询
function ProjectErrorInfo(data) {
  return request({
    method: "POST",
    url: "Project/ProjectErrorInfo?ProjectID=" + JSON.parse(sessionStorage.getItem('projectAddRequest')).ProjectID,
    data
  });
}
// 回传数据
function ProjectReconnaissance(data) {
  return request({
    method: "POST",
    url: "Project/ProjectReconnaissance?ProjectID=" + JSON.parse(sessionStorage.getItem('projectAddRequest')).ProjectID,
    data
  });
}
// 斜屋顶的基本信息 /api/ObliqueRoof/GetCADDetailInfo
function ObliqueRoofInfo(data) {
  return request({
    method: "POST",
    url: "ObliqueRoof/GetCADDetailInfo?ProjectID=" + JSON.parse(sessionStorage.getItem('projectAddRequest')).ProjectID
  })
}
// 平屋顶的基本信息 /api/ProjectFlatRoof/GetInfo
function FlatRoofInfo(data) {
  return request({
    method: "POST",
    url: "ProjectFlatRoof/GetInfo?ProjectID=" + JSON.parse(sessionStorage.getItem('projectAddRequest')).ProjectID
  })
}
function UploadFile(data) {
  return request({
    method: "post",
    url: "File/UploadFile",
    data,
  });
}
function DownloadPdfPic(data) {
  return request({
    method: "get",
    url: "File/DownloadPdfPic?fileId=" + data,
    data,
  });
}
export { AddFlatRoof, AddObliqueRoof, AddTask, GetTaskState, UpdataTask, UpdateJson, GetList, ProjectErrorInfo, ProjectReconnaissance, GetComponentDict, ProjectBasicInfo, ObliqueRoofInfo, FlatRoofInfo, UploadFile, DownloadPdfPic };
