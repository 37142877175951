import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'lib-flexible'
import globleData from './store/globle'
import qs from 'qs';
import wx from 'weixin-js-sdk'
import vue3ResizeDrag from 'vue3-resize-drag'
import lazyPlugin from 'vue3-lazy'
// import store from 'vuex'
// import mitt from 'mitt'
// import pdf from 'vue-pdf'
// import vueshowpdf from 'vueshowpdf'
const app = createApp(App)
app.config.globalProperties.$globleData = globleData
app.config.globalProperties.$qs = qs;
app.use(Vant)
app.use(router)
app.use(vue3ResizeDrag)
app.use(wx)
app.use(lazyPlugin, {
  loading: require('./assets/加载.gif'), // 加载时默认图片
  error: require('./assets/加载.gif')// 图片失败时默认图片
})
// app.use(store)
// app.config.globalProperties.$mitt = mitt()
// app.use(pdf)
// app.use(vueshowpdf)
// app.config.ignoredElements = ['wx-open-launch-weapp']
app.mount('#app')
