<template>
  <!-- 锦浪dom -->
  <div class="card" v-if="customer === 'jinlang'">
    <div class="title">
      <div class="left"><span></span> &nbsp;农户信息</div>
      <img @click="isCollapse = !isCollapse" v-show="!isCollapse" src="./../assets/images/下拉.png" alt="" />
      <img @click="isCollapse = !isCollapse" v-show="isCollapse" src="./../assets/images/下拉1.png" alt="" />
    </div>
    <div class="contentKeep">
      <ul>
        <li>项目编号</li>
        <li>{{ farmerInfos.orderId }}</li>
      </ul>
      <ul>
        <li>客户姓名</li>
        <li>{{ farmerInfos.name }}</li>
      </ul>
    </div>
    <div v-if="isCollapse" class="content">
      <ul class="other">
        <li>项目地址</li>
        <li><span>{{ farmerInfos.area }}{{ farmerInfos.installerAddr }}</span></li>
      </ul>
      <ul>
        <li>经纬度</li>
        <li>{{ farmerInfos.coordinate }} </li>
      </ul>
      <ul v-if="farmerInfos.moduleBrand">
        <li>组件品牌</li>
        <li>{{ farmerInfos.moduleBrand }}</li>
      </ul>
      <ul>
        <li>组件型号</li>
        <li>{{ farmerInfos.capacity }}</li>
      </ul>
      <ul>
        <li>风雪压</li>
        <li>{{ farmerInfos.snowPressure }}</li>
      </ul>
    </div>
  </div>
  <!-- 晴天dom -->
  <!-- eslint-disable-next-line vue/valid-template-root -->
  <div class="card1" v-if="customer === 'qingtian'">
    <div class="title">
      <div class="left"><img src="@/assets/images/qingtian/农户信息.png" alt=""> &nbsp;农户信息</div>
      <img @click="isCollapse = !isCollapse" v-show="!isCollapse" src="./../assets/images/下拉.png" alt="" />
      <img @click="isCollapse = !isCollapse" v-show="isCollapse" src="./../assets/images/下拉1.png" alt="" />
    </div>
    <div class="contentKeep">
      <ul>
        <li>项目编号</li>
        <li>{{ farmerInfos.project_number }}</li>
      </ul>
      <ul>
        <li>客户姓名</li>
        <li>{{ farmerInfos.customer_name }}</li>
      </ul>
    </div>
    <div v-if="isCollapse" class="content">
      <ul class="other">
        <li>项目地址</li>
        <li><span>{{ farmerInfos.address }}</span></li>
      </ul>
      <ul>
        <li>经纬度</li>
        <li>{{ farmerInfos.lng_lat }} </li>
      </ul>
      <ul v-if="farmerInfos.brandName">
        <li>组件品牌</li>
        <li>{{ farmerInfos.brandName }}</li>
      </ul>
      <ul>
        <li>组件型号</li>
        <li>{{ farmerInfos.comPower }}</li>
      </ul>
      <ul>
        <li>风雪压</li>
        <li>{{ farmerInfos.wind_pressure }}</li>

      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, toRefs, reactive } from 'vue'
export default {
  setup () {
    const data = reactive({
      farmerInfos: {},
      isCollapse: true,
      customer: window.customer
    })
    onMounted(() => {
      setTimeout(() => {
        if (sessionStorage.getItem('FarmerInfo')) {
          data.farmerInfos = JSON.parse(sessionStorage.getItem('FarmerInfo'));
          if (data.farmerInfos.snowPressure === '0') {
            data.farmerInfos.snowPressure = '低风险'
          } else if (data.farmerInfos.snowPressure === '1') {
            data.farmerInfos.snowPressure = '中风险'
          } else {
            data.farmerInfos.snowPressure = '高风险'
          }
        }
      }, 500)
    })
    const methodsMap = {}
    return {
      ...toRefs(data),
      ...methodsMap
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/style/jinlang/farmerInfo.scss";
@import "@/style/qingtian/farmerInfo.scss";
</style>
