import { ProjectBasicInfo } from "@/api/api"
// 获取基本信息
function getProjectBasicInfo() {
  // 获取晴天地址栏传过来的客户信息
  const str = decodeURI(window.location.href)
  console.log(str);
  if (str && str.indexOf('?')>-1) {
    const arr = str.substring(str.indexOf('?') + 1).split('&')
    const data = {}
    arr.forEach(ele => {
      const arr = ele.split('=')
      data[arr[0]] = arr[1]
    })
    ProjectBasicInfo(data.orderId).then(res => {
      const infoData = res.data.data
      const data2 = {}
      data2.comBrand = infoData.moduleBrand // 组件品牌
      data2.comSize = infoData.capacity// 组件型号
      data2.ProjectID = infoData.orderId
      data2.ProjectNumber = ""
      data2.BelongToCompany = '锦浪'
      data2.Status = 0// 不需要传
      data2.ClientName = infoData.name
      data2.ProjectAddress = infoData.area + infoData.installerAddr
      data2.Longitude = infoData.coordinate.split(',')[0]
      data2.Latitude = infoData.coordinate.split(',')[1]
      data2.ProjectType = "" // 不需要传
      data2.WUBM = ""
      data2.RoofType = 1// 平屋顶 斜屋顶 内厦屋顶 外厦屋顶 复合屋顶
      data2.RoofNumber = 1
      data2.OrderBomJson = ""// 物料清单 可为空
      data2.CutBomJson = ""// 切料清单 可为空
      data2.designerAccount = ""
      data2.gaodeCode = infoData.gdCode
      data2.comInstallationMethod = Number(infoData.installType)
      data2.inverterBrandName = infoData.inverterBrand
      data2.serviceProviderTelephone = infoData.jcsMobile.substring(0, infoData.jcsMobile.indexOf(','))
      data2.surveyor = infoData.surveyName
      data2.SnowPressure = Number(infoData.snowPressure)

      sessionStorage.setItem('projectAddRequest', JSON.stringify(data2))
      sessionStorage.setItem('FarmerInfo', JSON.stringify(infoData))// 另外存储一份用来做农户信息的展示
    })
  }
}
export { getProjectBasicInfo }