import { GetComponentDict } from "@/api/api"
// 获取基本信息
function getBaseInfo() {
  // 获取晴天地址栏传过来的客户信息
  const str = decodeURI(window.location.href)
  console.log(str);
  if (str && str.indexOf('?')>-1) {
    const arr = str.substring(str.indexOf('?') + 1).split('&')
    const data = {}
    arr.forEach(ele => {
      const arr = ele.split('=')
      data[arr[0]] = arr[1]
    })
    GetComponentDict().then(res => { // 通过地址栏传的物料编码匹配组件信息
      console.log(res.data.data);
      var comData = res.data.data.filter(item => item.wubm === data.material_code)[0]
      const data2 = {}
      data2.comBrand = comData.brandName // 组件品牌
      data2.comSize = comData.comPower.toString() // 组件型号
      data2.ProjectID = data.project_id
      data2.ProjectNumber = data.project_number
      data2.BelongToCompany = '晴天'
      data2.Status = 0// 不需要传
      data2.ClientName = data.customer_name
      data2.ProjectAddress = data.address
      data2.Longitude = data.lng_lat.split(',')[0]
      data2.Latitude = data.lng_lat.split(',')[1]
      data2.ProjectType = "" // 不需要传
      data2.WUBM = data.material_code
      data2.RoofType = 1// 平屋顶 斜屋顶 内厦屋顶 外厦屋顶 复合屋顶
      data2.RoofNumber = 1
      data2.OrderBomJson = ""// 物料清单 可为空
      data2.CutBomJson = ""// 切料清单 可为空
      data2.designerAccount = ""
      data2.gaodeCode = ""
      data2.comInstallationMethod = 0
      data2.inverterBrandName = ""
      data2.serviceProviderTelephone = ""
      data2.surveyor = ""
      data2.draftImages = ""
      if (data.wind_pressure === '低风压') {
        data2.SnowPressure = 1
      } else if (data.wind_pressure === '中风压') {
        data2.SnowPressure = 2
      } else {
        data2.SnowPressure = 3
      }
      sessionStorage.setItem('projectAddRequest', JSON.stringify(data2))
      sessionStorage.setItem('FarmerInfo', JSON.stringify(Object.assign(data, { brandName: comData.brandName, comPower: comData.comPower })))// 另外存储一份用来做农户信息的展示
    })
  }
}
export { getBaseInfo }