const data = {
  // projectInfo: { // 客户基本信息
  //   ProjectID: "", // 项目id
  //   BelongToCompany: "", // 所属公司
  //   Surveyor: "", // 勘察人员姓名
  //   Status: 0, // 审核状态（由晴天审核）晴天独有
  //   ClientName: "", // 客户姓名
  //   ProjectAddress: "", // 项目地址
  //   Longitude: "", // 经度
  //   Latitude: "", // 纬度
  //   ProjectType: "", // 项目类型 *字段来源客户 *
  //   WUBM: "", // 物料编码 *字段来源客户*
  //   SnowPressure: 1, // 1：低风压地区  2：中风压地区  3：高风压地区 *字段来源客户*
  //   RoofType: 1, // 平屋顶 斜屋顶 内厦屋顶 外厦屋顶 复合屋顶
  //   RoofNumber: 1, // 屋面个数
  //   OrderBomJson: "", // 物料清单
  //   CutBomJson: "", // 切料清单
  //   DesignerAccount: "", // 设计师账号
  //   ComInstallationMethod: 0, // 0.常规  1.平改坡 锦浪独有	组件安装方式
  // },
  // flatRoofBaseInfo: { // 平屋顶基本信息
  //   RoofID: "", // 屋面ID
  //   EastWestLength: 0, // 东西长度
  //   NorthSouthWidth: 0, // 南北宽度
  //   HouseHeight: 0, // 房屋高度
  //   FacingDirection: 1, // 房屋朝向 1.南偏东。2南偏西。3正南。
  //   FacingDegree: 25, // 朝向度数
  //   GridConnectionDistance: 0, // 距并网点距离
  //   RoofingType: 1, // 屋面类型 /1：现浇板  2：预制板  3：木望板  4：凛橼结构
  //   RoofSlabThickness: 0, // 屋面厚度
  //   InstallationMethod: 1, // 安装方式 1：混泥土基墩  2：膨胀螺栓  3：混泥土+膨胀
  //   TopViewPic: "", // 俯视图
  //   RearViewPic: "", // 后视图
  //   TopViewJson: "", // 俯视图Json
  //   RearViewJson: "", // 后视图Json
  //   Cratetime: "", // 提交时间 detetime
  // },
  // 障碍物
  roadObstacleModels: [],
  billboardObstacleModels: [],
  chimneyObstacleModels: [],
  doorsAndWindowObstacleModels: [],
  heaterObstacleModels: [],
  neighborHouseObstacleModels: [],
  outSideObstacleModels: [],
  parapetWallsObstacleModels: [],
  ridgeOrnamentObstacleModels: [],
  roofingObstacleModels: [],
  sunRoomObstacleModels: [],
  terraceParapetWallsObstacleModels: [],
  treeObstacleModels: [],
  wireObstacleModels: [],
  wirePoleObstacleModels: []
}
export default data